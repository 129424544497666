import * as React from "react"
import * as css from "./download-button.module.scss"

export function DownloadButton(props: {
  url: string;
  imageUrl: string;
  className?: string;
}) {

  return (
    <a className={props.className} href={props.url} target="_blank">
      <img className={css.downloadButton} src={props.imageUrl} alt="Download" />
    </a>
  )
}
