import {
  ASK_NEBULA_URL,
  FACTORY_ANDROID_URL,
  FACTORY_IOS_URL,
  NEBULA_ANDROID_URL,
  NEBULA_IOS_URL,
} from "../../../constants/products-url"
import { Product } from "../product"

export const productInfoList: Product[] = [
  {
    title: 'Nebula',
    description: `nebula_description_products`,
    image: "/products/nebula_phone+desktop.png",
    imageMob: "/products/nebula_phone+desktop.png",
    iosUrl: NEBULA_IOS_URL,
    androidUrl: NEBULA_ANDROID_URL,
    webUrl: ASK_NEBULA_URL,
    technologies: [
      {
        title: "IOS",
        technologies: [
          "VIPER",
          "CI/CD (Fastlane)",
          "Socket.io",
          "SwiftLint",
          "SwiftGen",
          "Image-Driven Development",
          "WidgetKit",
          "ApplePay",
        ],
      },
      {
        title: "Android",
        technologies: [
          "Dagger 2",
          "Android Architecture Components (Room ect.)",
          "Clean architecture",
          "Retrofit",
          "rx 2",
          "Socket.io",
        ],
      },
      {
        title: "Web",
        technologies: [
          "React.js",
          "Node.js",
          "Nest.js",
          "Next.js",
          "TypeScript",
          "Web Sockets",
        ],
      },
    ],
  },
  // {
  //   title: "Ask Nebula",
  //   description: "asknebula_description_products",
  //   image: "/products/ask-nebula-slide.png",
  //   imageMob: "/products/img-mb-ask.png",
  //   webUrl: ASK_NEBULA_URL,
  //   technologies: [
  //     {
  //       technologies: [
  //         "React.js",
  //         "Node.js",
  //         "Nest.js",
  //         "Next.js",
  //         "TypeScript",
  //         "Web Sockets",
  //       ],
  //     },
  //   ],
  // },
]
