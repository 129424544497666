// extracted by mini-css-extract-plugin
export var section = "product-section-module--section--3V8V4";
export var container = "product-section-module--container--4B7t+";
export var grid = "product-section-module--grid--iJRyP";
export var reverse = "product-section-module--reverse--XuK9E";
export var text = "product-section-module--text--TbxqZ";
export var boldLabel = "product-section-module--bold-label--vEcmU";
export var label = "product-section-module--label--DUEAf";
export var title = "product-section-module--title---O3dB";
export var description = "product-section-module--description--8s6jq";
export var actions = "product-section-module--actions--1ueiA";
export var downloadButton = "product-section-module--downloadButton--iEGDN";
export var technologies = "product-section-module--technologies--FfFGL";
export var techBlock = "product-section-module--tech-block--MDQvr";
export var image = "product-section-module--image--zlTuk";