import * as React from "react"
import * as css from "./product-list.module.scss"
import { ProductSection } from "../product-section/product-section"
import { productInfoList } from "./product-info-list"

export function ProductList() {

  return (
    <div className={css.list}>
      {productInfoList.map((product, index) => (
        <ProductSection key={index} reverse={index % 2 !== 0} {...product} />
      ))}
    </div>
  )
}
