export const NEBULA_IOS_URL =
  "https://apps.apple.com/us/app/nebula-horoscope-widgets/id1459969523"
export const NEBULA_ANDROID_URL =
  "https://play.google.com/store/apps/details?id=genesis.nebula"

export const FACTORY_IOS_URL =
  "https://apps.apple.com/ua/app/factory-empire/id1540579483"
export const FACTORY_ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.Obrio.FactoryEmpire&hl=en&gl=US"

export const ASK_NEBULA_URL = "https://asknebula.com/"
