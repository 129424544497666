import * as React from "react"
import * as css from "./product-section.module.scss"
import classNames from "classnames"
import { Chips } from "../../../common/chips/chips"
import { DownloadButton } from "../../../common/download-button/download-button"
import { LinkButton } from "../../../common/button/link-button"
import { Product } from "../product"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function ProductSection(props: Product & { reverse: boolean }) {
  const _ = useTranslation()
  const reverseClass = props.reverse ? css.reverse : ""
  return (
    <section className={css.section}>
      <div
        className={classNames("container", "overflow-visible", css.container)}
      >
        <div className={classNames(css.grid, reverseClass)}>
          <div className={css.text}>
            <h2
              className={css.title}
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
            <p className={css.description}>{_[props.description]}</p>
            <div className={css.boldLabel}>{_.technologies}:</div>

            <div className={css.technologies}>
              {props.technologies.map((techBlock, index) => (
                <div key={index} className={css.techBlock}>
                  {techBlock.title ? (
                    <div className={css.label}>{techBlock.title}</div>
                  ) : (
                    ""
                  )}
                  <div>
                    {techBlock.technologies.map((name, nameIndex) => (
                      <Chips key={nameIndex}>{name}</Chips>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            

            <div className={css.actions}>
              {props.iosUrl && <DownloadButton className={css.downloadButton} url={props.iosUrl} imageUrl="/download-app-store.svg" />}
              {props.androidUrl && (
                <DownloadButton url={props.androidUrl} imageUrl="/download-google-play.svg" />
              )}
              {props.webUrl && <DownloadButton url={props.webUrl} imageUrl="/download-web.svg" />}
            </div>
          </div>
          <div className={classNames(css.image, reverseClass)}>
            <picture>
              <source media="(max-width: 768px)" srcSet={props.imageMob}/>
              <img src={props.image} alt="Nebula" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}
