import * as React from "react"
import { ProductList } from "./product-list/product-list"
import { Helmet } from "react-helmet"
import { useTranslation } from "../../common/use-translations/use-translations"

export function ProductsPage() {
  const _ = useTranslation()
  return (
    <>
      <Helmet>
        <title>{_.products} - OBRIO</title>
      </Helmet>
      <ProductList /></>
  )
}
